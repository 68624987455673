import './App.css';

function App() {
  return (
    <div className="App">
      <header className="">
      </header>
      <body className="">
        <p>
          <code>github</code><span>&nbsp;&nbsp;</span>
          <a
            className="App-link"
            href="https://github.com/kricli"
            target="_blank"
            rel="noopener noreferrer"
          >
            <code>@kricli</code>
          </a>
        </p>
      </body>

    </div>
  );
}

export default App;
